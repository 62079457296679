<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <!-- Table Container Card -->

    <b-card no-body>
     
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <!-- <label>Status</label> -->
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sites"
              label="site_name"
              class="w-100"
              placeholder="Project Site"
              @input="filterTable"
              v-model="siteData"
              :clearable="false"
            >
            <template #selected-option="{ site_name }">
                <span v-if="site_name.length < 23">{{site_name}}</span>
                <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
              </template>
            </v-select>
          </b-col>

          

          <b-col 
           cols="12"
           md="8"
           class="mb-md-0 mobile_tab_max_width_flex"
          >

            <div class="d-flex align-items-center justify-content-end mobile_tab_display_block">
              <b-button variant="danger" class="mobile-margin-bottom mr-1"
                :to="{name:'quality-management-elements',params:{site_id:siteData != null ? siteData._id : 'all-site'}}"
              >
                <span class="text-nowrap">Elements</span>
              </b-button>

              <b-button variant="primary" class="mobile-margin-bottom"
                :to="{name:'quality-management-general-awareness',params:{site_id:siteData != null ? siteData._id : 'all-site'}}"
              >
                <span class="text-nowrap">General Awareness</span>
              </b-button>
              

            </div>
          </b-col>
          
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                placeholder="Search..."
                @input="filterTable"
                autocomplete="off"
              />
              <b-button
                variant="warning"
                class=""
                :to="{name:'add-qm-unit',params:{site_id: siteData != null ? siteData._id : 'all-site'}}"
                >
                <span class="text-nowrap">Add Unit</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative sTable common_big_tbl"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <template #cell(info)="items">                
          <feather-icon :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon' " @click="items.toggleDetails" class="my-1" />
        </template>

        <!-- Column: User -->
        <template #cell(site)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.site.site_name | capitalize}}</span>
         
        </template>

        <!-- Column: Role -->
        <template #cell(name)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.name | capitalize}}</span>
         
        </template>

        

        
        
        <template #cell(status)="items">
           
            <b-badge
              pill
              :variant="variantColor(items.item.status)"
              class="text-capitalize"
              @click="items.toggleDetails"
            >
              {{ items.item.status}}
            </b-badge>
         
        </template>

        <template #cell(created_at)="items">
           
            <span @click="items.toggleDetails">{{sitedateTime(items.item.created_at,siteData.pdf_date_format,siteData.pdf_time_format)}}</span>
         
        </template>

        <template #cell(actions)="items">

          
          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            

            <b-dropdown-item :to="{ name: 'edit-qm-unit', params: { id: items.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
              <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
              <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteItem(items.item)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            

            
          </b-dropdown>  -->
          <b-link  :to="{ name: 'edit-qm-unit', params: { id: items.item._id } }"
            v-b-tooltip.hover.v-warning
            title="Edit"
            variant="outline-warning"
          >
              <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
              
          </b-link>

            <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)">
                <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
            </b-link>

           <b-link @click="deleteItem(items.item)"
            v-b-tooltip.hover.v-warning
            title="Delete"
            variant="outline-warning"
          >
              <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
              
          </b-link>
        </template>

        <template #row-details="items">
          
          
          <div class="px-2 fbDetail mb-1">
            <b-row v-if="items.item.allelements.length > 0 && items.item.status == 'active'">
              <!-- <b-col cols="12 pb-1">
                <h3 class="text-center mt-1 mb-1"><strong>Assigned Categories</strong></h3>
              </b-col> -->

              <div class="pt-2 text-center">
                <div class="card-deck text-center custom-card elemCard" v-for="(element,index) in items.item.allelements" :key="index">

                  <div class="card box-shadow border" :class="element.selected == false ? 'border-secondary' : 'border-danger'" @click="updateSelect(items.index,index)" style="height:100px">
                    
                    <div class="bg-success" style="padding: 5px; width: 5px; border-radius: 25px; margin-left: auto; margin-right: 2px;margin-top:2px"/>
                    
                    <p class="text-center mt-2 categoryH5 pb-0 mb-0 text-white" style="font-size: 13px;" :title="element.name.length > 20 ? element.name : ''"> {{element.name | truncate20}} </p>          
                    
                  
                  </div>
                </div>

              </div>
              
            </b-row>
            <div v-else>
              <div class="pt-2 text-center">
                <p class="text-center h5"> No Element Found. </p>
              </div>
            </div>

            <div class="pb-2">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="items.toggleDetails"
                size="sm"
              >
                Hide Detail
              </b-button>
            </div>

          </div>

          <!-- <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
          </b-card> -->
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,VBTooltip,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data() {
    return {
       tableColumns: [
            { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '1%'}},
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '20%'}},
            { key: 'name', label: 'Inspection Unit Name', sortable: true , thStyle:  {width: '30%'}},
            //{ key: 'checklist_form', label: 'Checklist Form', sortable: false , thStyle:  {width: '20%'}},
            { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '15%'}},
            { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '15%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '19%'}},
          ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        sites : [],
        siteData : ''
    }
  },
  methods : {
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     site:this.siteData ? this.siteData._id : null,
                   },
                   api: '/api/qm-unit-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    statusChange(item){
      
      var elemLink = item.allelements.find(it => { return it.selected == true});
      elemLink = elemLink ? true : false;
      var locLink = item.locations.length > 0 ? true : false;

      if (item.status == 'active' && locLink == true) {
          var msg = 'Some location are linked with this unit. Are you sure want to inactivate this record?';
          var message='Unit Inactivated Successfully.';
      }else if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='Unit Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='Unit Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-qm-unit-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },

    deleteItem(item){
        var elemLink = item.allelements.find(it => { return it.selected == true});
        elemLink = elemLink ? true : false;
        
        var locLink = item.locations.length > 0 ? true : false;

        if (locLink == true) {
            var msg = 'Some location are linked with this unit. Are you sure want to delete this record?';
        }else{
            var msg = 'Are you sure want to delete this record?';
        }

        this.decisionAlert(msg)
        .then(result => {
            if (result.value) {
                  return this.$store.dispatch(POST_API, {
                      data:{
                          id: item._id,
                          status:'deleted'
                      },
                      api:"/api/change-qm-unit-status",
                  })
                  .then(() => {
                      if (this.$store.getters.containsErrors) {
                          this.error_message = this.$store.getters.getErrors;
                          this.showAlert();
                      } else {
                          this.successAlert();
                          this.$refs.refUserListTable.refresh();
                      }
                  });
            }
        })
        .catch(err => {
            
        })
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Quality Management'
      },{
        to:null,
        text: 'Settings'
      },{
        to:{name:'quality-management-inspection-area',params:{site_id:this.$route.params.site_id}},
        text: 'Inspection Area',
      },{
        to:null,
        text: 'Units',
        active:true
      }];
      return item;
    },
    allSites(){
      return this.$store.dispatch(POST_API, {
         data:{
              role:this.$store.getters.currentUser.role,
              //om_sites:this.$store.getters.currentUser.om_sites,
         },
         api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites = this.$store.getters.getResults.data;

              /*var obj = {
                _id:'all-site',
                site_name:'All Project Sites',
                pdf_date_format: 'DD MMM YYYY',
                pdf_time_format: 'HH:mm'
              }
              this.sites.unshift(obj);*/

              this.siteData = this.defaultSite(this.sites);
              this.filterTable();
              return this.sites;
          }
      });
    },
    updateSelect(unitIndex, elementIndex){

        return this.$store.dispatch(POST_API, {
          data:{
            site: this.items[unitIndex].site._id,
            unit:this.items[unitIndex]._id,
            element:this.items[unitIndex].allelements[elementIndex]._id,
            selected:this.items[unitIndex].allelements[elementIndex].selected
          },
          api:"/api/assign-elements-to-unit",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.errorAlert();
          } else {
            var data = this.$store.getters.getResults.data;

            if (this.items[unitIndex].allelements[elementIndex].selected == true) {
              this.items[unitIndex].allelements[elementIndex].selected = false;
            }else{
              this.items[unitIndex].allelements[elementIndex].selected = true;
            }

          }
        });
      },
  },
  mounted(){
    this.allSites().then(() => {
      if (this.$route.params.site_id != '') {
        var siteData = this.sites.filter(item => {return item._id == this.$route.params.site_id});  
        
        this.siteData = siteData.length > 0 ? siteData[0] : this.siteData; 
        
        this.filterTable();
      }  
    })

    

    
  }
  
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .border-danger {
    border: 2px solid #ea5455 !important;
    padding: 2px !important;
  }
  .border-secondary {
    border: 2px solid rgb(221, 221, 221) !important;
    padding: 2px !important;
  }
  .card-body{
    padding-top: 1rem !important;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
